
import Layout from '../../../components/layout/Layoutexport-en';

import React from 'react';
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"
import { StaticQuery, graphql } from "gatsby"

import CardProductsExport from '../../../components/CardProductsExport'
import { Link } from 'gatsby';




const seo={
  title: "high quality hand hygiene products ",
  desc:  "We manufacture high quality hand washing and disinfection products for the whole region and the world",
  image: "higiene-de-manos.jpg",
  pathname: "/export/en/hand-hygiene/",  
  alternateEn: "/export/en/hand-hygiene/",
  alternateEs: "/export/es/higiene-de-manos/",
  alternateEsec: "/higiene-de-manos/",
  alternateDefault: "/export/es/higiene-de-manos/"
}

const siteVerOpts = [

  {
    label: 'Internacional',
    value: '',

  },


  {
    label: 'Ecuador',
    value: '/higiene-de-manos/',

  },


];

const langOpts = [

  {
    label: 'ES',
    value: '',

  },


  {
    label: 'EN',
    value: '/export/en/hand-hygiene/',

  },


];

const CategorieExport = ({location, lang = "en", data}) => {






  return(

          <Layout location={location} crumbLabel="Hand Hygiene  "
            siteVerOpts={siteVerOpts} langOpts={langOpts}
            alternates alternateEn={seo.alternateEn} alternateEs={seo.alternateEs} alternateEsec={seo.alternateEsec} alternateDefault={seo.alternateDefault}
            title={seo.title} desc={seo.desc} banner={seo.image} pathname={seo.pathname}>

      <section id="heading-banner" className={`mt-2 mb-0 mr-0 border-0 p-0 bg-blue-900`}>
                    <div className="flex flex-row">
                          <div className="grow border-0">
                              <h1 className={`mt-8 ml-2 text-base font-semibold leading-tight tracking-wider py-10
                                              sm:mt-6 sm:ml-6 sm:text-2xl 
                                              md:mt-6 md:ml-12 md:text-2xl md:tracking-wider md:leading-snug
                                              lg:mt-8 lg:text-3xl lg:tracking-wide lg:leading-snug 
                                              xl:mt-8 xl:text-5xl xl:tracking-wider
                                              text-left text-white
                                              `}>
                              Hand Hygiene
                              </h1>

                          </div>
                          <div className="h-44 lg:h-96 border-0">

                            <StaticImage src="../../../images/banner-higiene-de-manos.png"
                                            className="object-contain h-full"
                                            imgClassName=""


                                            alt="Categoria de productos: Higiene de manos profesional"
                                            placeholder="blurred"
                                            layout="constrained"

                            />
                          </div>


                    </div>
      </section>

      <section id="intro-paragraph">
              <div className="w-full mx-auto mt-0 mb-12 px-2 bg-gray-200">
                <div className="p-6 lg:pt-12 lg:pb-36">
                  <h2 className="my-6 lg:my-8 text-primary-default text-center text-2xl md:text-3xl font-semibold">Professional products for hand hygiene at work</h2>
                  <div className="m-0 sm:m-4 lg:w-5/6 lg:mx-auto">
                    <p className="text-2xl font-normal leading-relaxed  text-primary-default mt-6 mb-8  text-md md:text-lg lg:text-xl text-justify">
                      <StaticImage src="../../../images/higiene-de-manos-profesional.jpg"
                                  className={`

                                    mt-2 mb-8 float-none w-full
                                    sm:mt-6 sm:ml-6 sm:mr-0 sm:float-right sm:w-72 sm:mb-4
                                    md:ml-10 md:mb-4
                                    lg:mt-6 lg:w-96 lg:h-48
                                    shadow-md

                                  `}

                                  imgClassName=""
                                  alt="La importancia de la higiene de manos en el trabajo"
                                  placeholder="blurred"
                                  layout="constrained" /><br/>
                        Hand hygiene is a fundamental requirement in any food safety and quality system or Good Manufacturing Practices. Finally, the promotion of proper hand hygiene is, in any work environment, one of the most effective methods to prevent contagion and reduce absenteeism due to illnesses caused by pathogenic microbes. Thanks to our products, you will be able to implement a proper hand hygiene program in your organization.
                    </p>
                  </div>

                </div>
              </div>
</section>

<section id="grid-productos" className="my-12">
    <h3 className="my-6 md:my-8 text-center mx-auto text-primary-lighter text-2xl font-semibold">- Discover our products for professional hand hygiene -</h3>
    <div className=" bg-white px-4 sm:px-6 lg:pt-6 lg:pb-28 lg:px-8">
     <div className="relative max-w-7xl mx-auto">

      <div className="relative max-w-lg mx-auto grid gap-8 md:grid-cols-2 lg:grid-cols-3 lg:max-w-none">



      {data.allProducts2CategoryenJson.edges.map( ({node}) => (
        <div key={node.id} className="flex flex-col overflow-hidden hover:bg-zinc-100 rounded-lg hover:shadow-md border">
          <Link to={`/export/${lang}/${node.slug}/`} className="text-lg font-semibold text-center text-primary-lighter hover:text-zinc-500 hover:no-underline">
          <CardProductsExport className="p-6">

              <div className="flex-1">
                <GatsbyImage image={getImage(node.featuredImage)}
                        className="flex justify-center my-4 mx-4"
                        alt={node.title}
                 />
                      <div className="flex flex-wrap mx-auto my-4 justify-center">
                       {node.title}
                     </div>

              </div>

          </CardProductsExport>
          </Link>
        </div>
      ))}



     </div>
    </div>
  </div>
</section>


          </Layout>

        );
}

export const query = graphql`
  query CategoryHandHygieneExportQuery {
    allProducts2CategoryenJson(filter: {categorySlug: {eq: "hand-hygiene"}}) {
      edges {
        node {
          featuredImage {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
            }
          }
          id
          slug
          title
        }
      }
    }
  }
`

export default CategorieExport;
